import {gql} from 'apollo-boost';

export const ME = gql`
  query {
    User {
      get {
        email
        firstName
        lastName
        verifyCode
        verified
        company
        phone
        error
        id
        photo
        premium_credit_subscription_cancel_at
        premium_credit_subscription_status
        premium_credits_pricing_package
        premium_credits_pricing_package_interval
        left_premium_credits
        combo_pricing_package
        promoData {
          hexowatch_ltd
          hexomatic_ltd
        }
      }
    }
  }
`;

export const GENERATE_APOLLO_DATA = gql`
  query {
    User {
      generateApolloData {
        message
        error
      }
    }
  }
`;

export const HEXOFY_USER = gql`
  query HEXOFY_USER {
    HexofyUser {
      getHexofyUser {
        hfUser {
          id
          user_legacy_id
          pricing_package
          pricing_package_interval
          pricing_package_count
          stripe_subscription_id
          paypal_subscription_id
          subscription_status
          billing_info {
            next_billing_date
            last_billing_date
            subscription_status
          }

          pending_payment
          error_code
          settings {
            storage
            checks
          }
        }
      }
    }
  }
`;

export const USER_PLANS_QUERY = gql`
  query USER_QUERY {
    User {
      getUserPlans {
        hexomatic {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexowatch {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        hexometer {
          pricing_package
          pricing_package_interval
          pending_payment
          
        }
        hexospark {
          pricing_package
          pricing_package_interval
          pending_payment
        }
        error_code
      }
    }
  }`;

export const GET_AUTOMATIONS = gql`
  query($settings: GetAutomationsUserInput) {
    HexomaticAutomation {
      getAutomationsPublic(settings: $settings) {
        automations {
          id
          name
          prices {
            id
            unit_name
            credit_price
            premium_credit_price
          }
          url
          meta_title
          short_description
          long_description
          long_description_details
          icon_url
          category {
            id
            name
          }
          type {
            id
            name
          }
          is_new
        }
        error_code
      }
    }
  }
`;

export const LEFT_PACKAGES_COUNT = gql`
  query {
    User {
      getHexomateLeftLTDPackagesCount {
        count
        error
        message
      }
    }
  }
`;

export const PAYMENT_METHOD_QUERY = gql`
  query {
    User {
      creditCardInfo {
        number
        name
        expiry
      }
    }
  }
`;

export const GET_USER_WATCH_SETTINGS_QUERY = gql`
  query {
    UserWatchSettings {
      get {
        pricing_package
      }
    }
  }
`;

export const GET_HEXOMETER_USER_SETTINGS = gql`
  query($user_id: Int!) {
    HexometerUserSettings {
      get(user_id: $user_id) {
        pricing_package
        pricing_package_interval
        pricing_package_count
      }
    }
  }
`;

export const GET_HEXOMATIC_USER = gql`
  query GET_HEXOMATIC_USER {
    HexomaticUser {
      getByLegacyId {
        id
        automation_credit
        premium_automation_credit
        user_legacy_id
        pricing_package
        pricing_package_interval
        pricing_package_count
        stripe_subscription_id
        paypal_subscription_id
        stripe_subscription_id
        automation_credit
        premium_automation_credit
        left_premium_automation_credit
        left_automation_credit
        error_code
        pending_payment
        can_use_trial
        trial_packages
        billing_info {
          is_promo
        }
      }
    }
    User {
      get {
        verified
        email
        left_premium_credits
        firstName
        promoData {
          hexowatch_ltd
          hexomatic_ltd
        }
      }
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query($settings: ListProductsInput) {
    Billing {
      listProducts(settings: $settings) {
        products {
          hexomaticProducts {
            id
            name
            interval
            productId
            amount
            max_stack_count
            limits {
              additional_premium_credits_count
              credits_count
            }
            actions {
              scraping_recipe {
                can_create_scraping_recipe
              }
              workflow {
                scheduling
                simultaneous_running_workflow_count
              }
              specify_server_location
            }
            integrations {
              integrately {
                allowed
              }
              pabbly {
                allowed
              }
              syncspider {
                allowed
              }
              zapier {
                allowed
              }
              webhook {
                allowed
              }
              hexomatic_api {
                allowed
              }
            }
            isPopular
          }
          comboProducts {
            id
            name
            enable
            interval
            amount
            productType
          }
        }
        error_code
      }
    }
  }
`;
export const LIST_CUSTOMER_CARD = gql`
  query($settings: ListCustomerCardsInput) {
    Billing {
      listCustomerCards(settings: $settings) {
        cards {
          id
          createdAt
          updatedAt
          cardId
          customerId
          brand
          lastDigits
          isDefault
        }
        error_code
      }
    }
  }
`;

export const GET_FLASH_PROMO_DATA = gql`
  query {
    User {
      getHcFlashPromoCronDate {
        end_date
        error_code
      }
    }
  }
`;


export const GET_ACCESS_TOKEN = gql`
  query ($settings: GetAccessTokenInput) {
    User {
      getAccessToken (settings: $settings) {
        error_code
        token
      }
    }
  }
`;


export const LEFT_PREMMIUMS_COUNT = gql`
  query {
    User {
      getPromoLeftSeats {
        seats
        error_code
      }
    }
  }
`;