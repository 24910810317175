import React, { useEffect } from 'react';
import SimpleLayout from '../../components/layout_simple';
import Registration from '../../components/registration';
import './styles.scss';
import { useQuery } from '@apollo/react-hooks';
import { ME } from '../../graphql/queries';
import { navigate } from 'gatsby';

const RegistrationPage = () => {
  const {data: userData} = useQuery(ME);

  useEffect(() => {
    if (userData && userData.User && userData.User.get && userData.User.get.id) {
      navigate('/');
    }
  }, [userData]);

  return (
    <SimpleLayout isClickable>
      <Registration />
    </SimpleLayout>
  );
};

export default RegistrationPage;
